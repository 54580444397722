import "../styles/BornToBeach.css";
import "../styles/newNavbar.css";
import Footer from "./Footer";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import React from "react";
import ReactPlayer from "react-player/lazy";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ScrollToTopButton from "./ScrollToTopButton";
import { Link } from "react-router-dom";
import CustomMap from "./CustomMap";
import PresseText from "./PresseText";
import TeburioWidget from "./TeburioWidget";

export interface BeachClubLandingPageProps {
  logo: string;
}

export default function BeachClub({ logo }: BeachClubLandingPageProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const landingImages = [
    "012",
    "018",
    "020",
    "028",
    "036",
    "044",
    "050",
    "053",
    "061",
    "063",
    "064",
    "076",
    "086",
    "092",
    "096",
    "113",
  ];
  const openingTimes: [string, number, number, boolean][] = [
    ["Montag", 0, 0, false],
    ["Dienstag", 0, 0, false],
    ["Mittwoch", 17, 22, true],
    ["Donnerstag", 17, 22, true],
    ["Freitag", 17, 24, true],
    ["Samstag", 12, 24, true],
    ["Sonntag", 12, 22, true],
  ];

  const openingTimesJuli: [string, number, number, boolean][] = [
    ["Montag", 0, 0, false],
    ["Dienstag", 0, 0, false],
    ["Mittwoch", 17, 22, true],
    ["Donnerstag", 17, 22, true],
    ["Freitag", 17, 24, true],
    ["Samstag", 12, 24, true],
    ["Sonntag", 12, 22, true],
  ];

  const pages = [1, 2];

  function isOpened() {
    let now = DateTime.now();
    let weekday = now.weekday;
    let openingHours = openingTimes[weekday - 1];
    return (
      openingHours[3] &&
      now.hour >= openingHours[1] &&
      now.hour <= openingHours[2]
    );
  }

  const opened = false; //isOpened();
  const winterpause = true;
  const closingTime = openingTimes[DateTime.now().weekday - 1][2];
  const sonderOeffnungen: string[] = [];
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  let offnung = document.getElementById("offnungszeiten");
  let menu = document.getElementById("menu");
  let mieten = document.getElementById("mieten");
  let events = document.getElementById("events");

  const navs = ["offnungszeiten", "menu", "mieten", "events"];

  const handleScrollTo = (
    tab: "offnungszeiten" | "menu" | "mieten" | "events" | string
  ) => {
    let y;
    switch (tab) {
      case "offnungszeiten":
        y = offnung!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "menu":
        y = menu!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "mieten":
        y = mieten!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "events":
        y = events!.getBoundingClientRect().top + window.pageYOffset;
        break;
      default:
        y = 0;
    }
    y = y - 75;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    let element = document.getElementById("titleImageWrapper");
    offnung = document.getElementById("offnungszeiten");
    menu = document.getElementById("menu");
    mieten = document.getElementById("mieten");
    events = document.getElementById("events");

    const handleScroll = () => {
      const offset = window.scrollY;
      setIsNavbarFixed(offset >= element!.getBoundingClientRect().height);
    };
    let searchParam = window.location.search;
    searchParam = searchParam.substring(1);
    if (navs.includes(searchParam)) {
      handleScrollTo(searchParam);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScrollTo]);

  return (
    <div className={"background"}>
      <ScrollToTopButton />
      {!isNavExpanded && (
        <>
          <div className={"titleImageWrapper"} id={"titleImageWrapper"}>
            <img
              //src={"/beachClub/imageHeader.jpg"}
              src={"/clubContent/website-beachclub_landingimage.jpg"}
              className={"titleImage desktop"}
              id={"titleImage"}
            />
            <img
              //src={"/beachClub/imageHeader.jpg"}
              src={"/clubContent/website-beachclub_landingimage_mobile.jpg"}
              className={"titleImage mobile"}
              id={"titleImage"}
            />
          </div>
          <div className={isNavbarFixed ? "navbar sticky" : "navbar"}>
            <div className={"navs"}>
              <div className={"navDesktop"}>
                <Link to={"/"} className={"navImageWrapper"}>
                  <img src={logo} className={"navImage"} alt={"Sunside"} />
                </Link>
                <div
                  className={"nav big"}
                  onClick={() => handleScrollTo("offnungszeiten")}
                >
                  ÖFFNUNGSZEITEN
                </div>
                <div className={"nav noCursor"}>{"|"}</div>
                <div
                  className={"nav big"}
                  onClick={() => handleScrollTo("menu")}
                >
                  MENÜ
                </div>
                <div className={"nav noCursor hideOnMobile"}>{"|"}</div>
                <Link to={"/contact"} className={"nav"}>
                  RESERVIERUNG
                </Link>
                <div className={"nav noCursor"}>{"|"}</div>
                <div className={"nav"} onClick={() => handleScrollTo("events")}>
                  EVENTS
                </div>
                <div className={"nav noCursor"}>{"|"}</div>
                <Link to={"/"} className={"nav"}>
                  BORN TO BEACH
                </Link>
              </div>
              <div className={"navMobile"}>
                <div className={"navRow"}>
                  <div
                    className={"nav"}
                    onClick={() => handleScrollTo("offnungszeiten")}
                  >
                    ÖFFNUNGSZEITEN
                  </div>
                  <div className={"nav noCursor"}>{"|"}</div>
                  <div className={"nav"} onClick={() => handleScrollTo("menu")}>
                    MENÜ
                  </div>
                </div>
                <div className={"navRow"}>
                  <Link to={"/contact"} className={"nav"}>
                    RESERVIERUNG
                  </Link>
                  <div className={"nav noCursor"}>{"|"}</div>
                  <div
                    className={"nav"}
                    onClick={() => handleScrollTo("events")}
                  >
                    EVENTS
                  </div>
                  <div className={"nav noCursor"}>{"|"}</div>
                  <Link to={"/"} className={"nav"}>
                    BORN TO BEACH
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className={!isNavbarFixed ? "navbarGhost none" : "navbarGhost"}
          ></div>
          <div className={"card bornToClubMain columnFlex"}>
            <div className={"cardHeader"} id={"offnungszeiten"}></div>
            <div className={"rowSplit"}>
              <div className={"rowContent"}>
                <div className={"oeffnungszeitenImage"}>
                  <img
                    src={"/clubContent/oeffnungszeiten-website-september.jpg"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"card kartenCard bornToClubAlt"}>
            <div className={"cardHeader beachClub"} id={"menu"}>
              Menü
            </div>
            <div className={"rowSplit"}>
              <div className={"rowContent"}>
                <div className={"subheading"}>GETRÄNKE&shy;KARTE</div>
                <Carousel
                  className={"pdfcarousel"}
                  autoPlay={true}
                  axis={"horizontal"}
                  showArrows={true}
                  infiniteLoop={true}
                  interval={5000}
                  width={"350px"}
                  showStatus={false}
                  showThumbs={false}
                  showIndicators={false}
                >
                  {pages.map((value) => (
                    <div key={value}>
                      <a href={"/pdfs/GETRAENKEKARTE_24.pdf"} target={"_blank"}>
                        <Document
                          className={"pdfWrapper"}
                          file={"/pdfs/GETRAENKEKARTE_24.pdf"}
                        >
                          <Page
                            pageNumber={value}
                            width={350}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          ></Page>
                        </Document>
                      </a>
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className={"rowContent"}>
                <div className={"subheading"}>SPEISE&shy;KARTE</div>
                <Carousel
                  className={"pdfcarousel"}
                  autoPlay={true}
                  axis={"horizontal"}
                  showArrows={true}
                  infiniteLoop={true}
                  interval={5000}
                  width={"350px"}
                  showStatus={false}
                  showThumbs={false}
                  showIndicators={false}
                >
                  {pages.map((value) => (
                    <div key={value}>
                      <a href={"/pdfs/SPEISEKARTE_24.pdf"} target={"_blank"}>
                        <Document
                          className={"pdfWrapper"}
                          file={"/pdfs/SPEISEKARTE_24.pdf"}
                        >
                          <Page
                            pageNumber={value}
                            width={350}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                        </Document>
                      </a>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
          <div className={"contactButtonWrapper"}>
            <Link to={"/contact"}>
              <button className={"reservierenButton"}>TISCHRESERVIERUNG</button>
            </Link>
          </div>
          {/*
          <div className={"card bornToClubMain columnFlex"}>
            <div className={"cardHeader beachClub"}>Anreise</div>
            <CustomMap />
          </div>
          */}
          <div className={"card bornToClubMain columnFlex"}>
            <div className={"cardHeader beachClub"} id={"events"}></div>
            <div className={"rowSplit"}>
              <div className={"rowContent"}>
                <div className={"beachClubEventsImage desktop"}>
                  <img src={"/clubContent/FLYER_event_ubersicht_desktop.jpg"} />
                </div>
                <div className={"beachClubEventsImage mobile"}>
                  <img src={"/clubContent/FLYER_event_ubersicht_phone.jpg"} />
                </div>
              </div>
            </div>
          </div>
          {/*}
          <div className={"card bornToClubMain columnFlex"}>
            <div className={"cardHeader beachClub"} id={"mieten"}>
              Mieten
            </div>
          </div>
          */}
          <div className={"card videoCard bornToClubMain columnFlex"}>
            <div className={"playerWrapper"}>
              <ReactPlayer
                pip={true}
                url="https://www.youtube.com/watch?v=ODsL7KWjBMw&feature=youtu.be&ab_channel=BorntoBeach"
                height={"100%"}
                width={"100%"}
              />
            </div>
          </div>
          <PresseText />
          <div className={"card bornToClubAlt carouselCard"}>
            <div className={"carouselWrapper"}>
              <Carousel
                className={"imageCarousel"}
                autoPlay={true}
                axis={"horizontal"}
                showArrows={true}
                infiniteLoop={true}
                interval={3000}
              >
                {landingImages.map((value) => (
                  <div key={value}>
                    <img
                      src={
                        "/landingPageImages/Born-To-Beach-Club_06062022_Christina-Draxler_" +
                        value +
                        ".jpg"
                      }
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </>
      )}
      <Footer footerTab={"none"} />
    </div>
  );
}
